import {View, Text, Image, TouchableOpacity, Alert} from 'react-native';
import React, {useState} from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import Empfangen from '../../../assets/images/illustrations/empfangen.png';
import Übergeben from '../../../assets/images/illustrations/u-bring-delivery-absender_2.png';
import Bezahlt from '../../../assets/images/illustrations/ubring-success.png';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken, selectUserInfo} from '../../../slices/userSlice';

import * as Location from 'expo-location';

export default function ConfirmHandOver({navigation, route}) {
	const params = route.params;

	const [loading, setLoading] = useState(false);

	const type = params.type;
	const requestID = params.requestID;

	//console.log(requestID);

	const token = useSelector(selectToken);
	const userInfo = useSelector(selectUserInfo);

	async function testIfAlreadyRated(ratingRecipientID, shipmentID) {
		try {
			let res = await axios.patch(
				process.env.APIURL + 'user/rating/',
				{
					recipientID: ratingRecipientID,
				},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			);

			let ratings = res.data.ratings;

			let rating = ratings.find((rating) => {
				return rating.shipmentID == shipmentID && rating.userID == userInfo.id;
			});

			if (rating) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.warn(error.response);
			return false;
		}
	}

	async function rateUser(ratingRecipientID, shipmentID) {
		let alreadyRated = await testIfAlreadyRated(ratingRecipientID, shipmentID);
		if (alreadyRated) {
			Alert.alert(
				'Bereits bewertet',
				'Du hast diesen Nutzer bereits bewertet.'
			);
			return;
		}

		navigation.navigate('rating', {
			ratingRecipientID,
			shipmentID,
		});
	}

	async function buttonHandler() {
		setLoading(true);
		const location = await Location.getCurrentPositionAsync({
			accuracy: Location.Accuracy.Highest,
		});

		axios
			.post(
				process.env.APIURL + 'shipment/request',
				{
					requestID: parseInt(requestID),
					type,
					location,
				},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					navigation.replace('authenticatedContainer', {
						screen: 'uMission',
						merge: true,
					});
					setLoading(false);
				}
			})
			.catch((e) => {
				console.log(e.response);
				if (e.response.data.message) {
					Alert.alert('Fehler', e.response.data.message, [
						{
							text: 'OK',
							onPress: () => navigation.navigate('main', {merge: true}),
						},
					]);
				}
				setLoading(false);
			});
	}

	if (params.action === 'recieve') {
		return (
			<View style={tw.style('flex-1 flex justify-evenly items-center p-6')}>
				<Text style={tw.style('text-xl font-bold text-gray-800')}>
					Quittieren der Übergabe
				</Text>
				<Image
					source={Empfangen}
					style={tw.style('w-5/5 h-75')}
					resizeMode="contain"
				/>
				<Text style={tw.style('text-lg font-bold text-gray-800')}>
					Bestätige die Übergabe des Pakets
				</Text>
				<View style={tw.style('flex flex-row justify-between')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => buttonHandler()}
						label="Bestätigen"
						loading={loading}
						disabled={loading}
						showIcon={loading}
					/>
				</View>
			</View>
		);
	} else if (params.action === 'handOver') {
		return (
			<View style={tw.style('flex-1 flex justify-evenly items-center p-6')}>
				<Text style={tw.style('text-xl font-bold text-gray-800')}>
					Quittieren der Übergabe
				</Text>
				<Image
					source={Übergeben}
					style={tw.style('w-5/5 h-75')}
					resizeMode="contain"
				/>
				<Text style={tw.style('text-lg font-bold text-gray-800')}>
					Ich habe die Sendung abgegeben
				</Text>
				<View style={tw.style('flex flex-row justify-between')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => buttonHandler()}
						label="Bestätigen"
						loading={loading}
						disabled={loading}
						showIcon={loading}
					/>
				</View>
			</View>
		);
	} else if (params.action === 'bringerPayed') {
		return (
			<View style={tw.style('flex-1 flex justify-evenly items-center p-6')}>
				<Text style={tw.style('text-xl font-bold text-gray-800')}>
					Quittieren der Bezahlung
				</Text>
				<Image
					source={Bezahlt}
					style={tw.style('w-5/5 h-75')}
					resizeMode="contain"
				/>
				<Text style={tw.style('text-lg font-bold text-gray-800')}>
					Ich habe die Bezahlung erhalten
				</Text>
				<View>
					<View style={tw.style('flex flex-row justify-between')}>
						<BackButton onPress={() => navigation.goBack()} />
						<NextButton
							onPress={() => buttonHandler()}
							label="Bestätigen"
							loading={loading}
							disabled={loading}
							showIcon={loading}
						/>
					</View>
					<View style={tw.style('justify-center items-center mt-5')}>
						<TouchableOpacity
							onPress={() => {
								rateUser(params.senderID, params.shipmentID);
							}}
						>
							<Text style={tw.style('text-base text-gray-600 underline')}>
								Sender bewerten
							</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={() => {
								rateUser(params.recipientEmail, params.shipmentID);
							}}
							style={tw.style('mt-2')}
						>
							<Text style={tw.style('text-base text-gray-600 underline')}>
								Empfänger bewerten
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		);
	} else {
		return (
			<View>
				<Text>Fehler</Text>
			</View>
		);
	}
}
