import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userInformation: {
        arrivalScreenAfterRegistration: '',
        addressInformation: {
            lat: 0,
            long: 0,
            streetName: '',
            houseNumber: '',
            postal: 0,
            city: '',
            state: '',
        },
        password: '',
        email: '',
        firstname: '',
        lastname: '',
        phoneNumber: '',
        isCompany: false,
        companyName: '',
        username: '',
        userID: 0,
    },
    extendedInformation: {
        licenseData: {},
        licensePlate: '',
    },
};

export const registerUserSlice = createSlice({
    name: 'registerNewUser',
    initialState,
    reducers: {
        setLicensePlate: (state, action) => {
            state.extendedInformation.licensePlate = action.payload;
            console.log(state.extendedInformation, 'REDUX');
        },
        setLicenseData: (state, action) => {
            state.extendedInformation.licenseData = action.payload;
            console.log(state.extendedInformation, 'REDUX');
        },
        setArrivalScreenAfterRegistration: (state, action) => {
            state.userInformation.arrivalScreenAfterRegistration =
                action.payload;
        },
        setPassword: (state, action) => {
            state.userInformation.password = action.payload;
        },
        setEmail: (state, action) => {
            state.userInformation.email = action.payload;
        },
        setNameAndCompany: (state, action) => {
            state.userInformation.username = action.payload.username;
            state.userInformation.firstname = action.payload.firstname;
            state.userInformation.lastname = action.payload.lastname;
            state.userInformation.isCompany = action.payload.isCompany;
            state.userInformation.companyName = action.payload.companyName;
        },
        setPhoneNumber: (state, action) => {
            state.userInformation.phoneNumber = action.payload;
        },
        setAddressInformation: (state, action) => {
            state.userInformation.addressInformation.streetName =
                action.payload.streetName;
            state.userInformation.addressInformation.houseNumber =
                action.payload.houseNumber;
            state.userInformation.addressInformation.city = action.payload.city;
            state.userInformation.addressInformation.long = action.payload.long;
            state.userInformation.addressInformation.lat = action.payload.lat;
            state.userInformation.addressInformation.postal =
                action.payload.postal;
            state.userInformation.addressInformation.state =
                action.payload.state;
        },
        setUserID: (state, action) => {
            state.userInformation.userID = action.payload;
        },
    },
});

export const {
    setLicensePlate,
    setLicenseData,
    setAddressInformation,
    setPhoneNumber,
    setNameAndCompany,
    setEmail,
    setPassword,
    setArrivalScreenAfterRegistration,
    setUserID,
} = registerUserSlice.actions;

export const selectRegisterUserInfo = (state) =>
    state.registerNewUser.userInformation;

export const selectExtendedInformation = (state) =>
    state.registerNewUser.extendedInformation;

export default registerUserSlice.reducer;
