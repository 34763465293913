import React from 'react';
import {View, Text, TouchableOpacity, Platform} from 'react-native';
import tw from '../lib/tailwind';

import IconHome from '../assets/images/icons/tabBar/icon_home';
import IconUSend from '../assets/images/icons/tabBar/icon_u-send';
import IconUBring from '../assets/images/icons/tabBar/icon_u-bring';
import IconUMission from '../assets/images/icons/tabBar/icon_u-mission';
import IconUAndMe from '../assets/images/icons/tabBar/icon_u-andme';
import {SafeAreaView} from 'react-native-safe-area-context';

export default function TabBar({state, descriptors, navigation}) {
	return (
		<SafeAreaView
			edges={['bottom']}
			style={tw.style(
				'pb-1 w-full flex flex-row justify-around items-center pt-4 px-3 bg-white shadow-md'
			)}
		>
			{state.routes.map((route, index) => {
				const {options} = descriptors[route.key];
				const label =
					options.tabBarLabel !== undefined
						? options.tabBarLabel
						: options.title !== undefined
						? options.title
						: route.name;

				const isFocused = state.index === index;

				const Icon = (props) => {
					switch (label) {
						case 'Home':
							return <IconHome {...props} />;

						case 'U-Send':
							return <IconUSend {...props} />;

						case 'U-Bring':
							return <IconUBring {...props} />;

						case 'U-Mission':
							return <IconUMission {...props} />;

						case 'U-AndMe':
							return <IconUAndMe {...props} />;

						default:
							return <IconHome {...props} />;
					}
				};

				const color = isFocused ? tw.color('primary') : '#AEAEB2';

				const onPress = () => {
					const event = navigation.emit({
						type: 'tabPress',
						target: route.key,
						canPreventDefault: true,
					});

					if (!isFocused && !event.defaultPrevented) {
						navigation.navigate({name: route.name, merge: true});
					}
				};

				const onLongPress = () => {
					navigation.emit({
						type: 'tabLongPress',
						target: route.key,
					});
				};

				return (
					<TouchableOpacity
						accessibilityRole="button"
						accessibilityState={isFocused ? {selected: true} : {}}
						accessibilityLabel={options.tabBarAccessibilityLabel}
						testID={options.tabBarTestID}
						onPress={onPress}
						onLongPress={onLongPress}
						style={tw.style('flex items-center')}
						key={index}
					>
						<Icon stroke={color} style={tw.style('mb-1')} strokeWidth="2" />
						<Text
							style={{
								color: color,
							}}
						>
							{label}
						</Text>
					</TouchableOpacity>
				);
			})}
		</SafeAreaView>
	);
}
