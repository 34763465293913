import {View, Text, TouchableOpacity, Linking, Alert} from 'react-native';
import React, {useState} from 'react';
import tw from '../../../lib/tailwind';
import {Avatar, CheckBox, Icon, Rating} from 'react-native-elements';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import {useSelector} from 'react-redux';
import {selectToken, selectUserInfo} from '../../../slices/userSlice';
import axios from 'axios';
import Ratings from '../../../uiKit/Ratings';

import * as WebBrowser from 'expo-web-browser';

export default function AcceptBringer({navigation, route}) {
	const params = route.params;

	const bringerData = params.bringerData;
	const packageData = params.packageData;

	const [checkLegal, setCheckLegal] = useState(false);
	const [loading, setLoading] = useState(false);

	const token = useSelector(selectToken);

	async function goToPayment() {
		setLoading(true);
		try {
			console.log(process.env.APIURL + 'shipment/paymentWebHook');
			let mollieRes = await axios.post(
				'https://api.mollie.com/v2/payments',
				{
					amount: {
						currency: 'EUR',
						value: (packageData.data.price / 1000).toFixed(2).toString(),
					},
					description: 'U-BRING Servicezahlung',
					redirectUrl: 'ubring://app/start',
					webhookUrl: 'https://u-bring.de/wp-json/v1/shipment/paymentWebHook',
					locale: 'de_DE',
					metadata: {
						shipmentID: bringerData.shipment_id,
						bringerID: bringerData.bringer_id,
						requestID: bringerData.id,
					},
				},
				{
					headers: {
						'Authorization': 'Bearer test_xm5bhzNaKNSntpcMqqQdRmKCxkv68G', // UBRING TEST
					},
				}
			);

			Linking.openURL(mollieRes.data._links.checkout.href);

			navigation.replace('authenticatedContainer', {
				screen: 'uMission',
				merge: true,
			});

			Alert.alert(
				'Bezahlung',
				'Die Zahlungsbestätigung ist austehehend. Bitte überprüfe in ein paar Minuten, ob die Zahlung erfolgreich war.'
			);

			setLoading(false);
		} catch (error) {
			console.log(error);
			Alert.alert(
				'Fehler',
				error.response.data?.error ||
					'Es ist ein unbekannter Fehler aufgetreten. Bitte versuche es später erneut.'
			);
			setLoading(false);
		}
	}

	return (
		<View style={tw.style('flex-1 flex justify-between p-6')}>
			<View>
				<View style={tw.style('flex flex-row items-center pt-5 pb-10')}>
					<Avatar
						rounded
						size={72}
						source={{
							uri:
								bringerData.bringer.avatarSrc !== null
									? bringerData.bringer.avatarSrc
									: 'https://api.multiavatar.com/' +
									  bringerData.bringer.user_email +
									  '.png',
						}}
						containerStyle={tw.style('shadow-md mr-4')}
					/>
					<View style={tw.style('flex')}>
						<Text style={tw.style('text-accent font-semibold')}>BRINGER</Text>
						<Text>
							{bringerData.bringer.first_name +
								' ' +
								bringerData.bringer.last_name}
						</Text>
						<Ratings userID={bringerData.bringer_id} />
					</View>
				</View>
				<View>
					<View style={tw.style('flex mb-4')}>
						<Text style={tw.style('font-medium mr-3')}>Adresse VON</Text>
						<Text>{packageData.originAddress.longString}</Text>
					</View>
				</View>
				<View>
					<View style={tw.style('flex ')}>
						<Text style={tw.style('font-medium mr-3')}>Adresse NACH</Text>
						<Text>{packageData.destinationAddress.longString}</Text>
					</View>
				</View>
			</View>

			<View>
				<View style={tw.style('flex items-end')}>
					<Text style={tw.style('text-xl')}>
						Kosten:{' '}
						<Text style={tw.style('font-semibold')}>
							{(packageData.data.price / 1000)
								.toFixed(2)
								.replace('.', ',')
								.toString() + '€'}
						</Text>
					</Text>
				</View>
				<View style={tw.style('pb-8 pt-4')}>
					<CheckBox
						checked={checkLegal}
						onPress={() => setCheckLegal(!checkLegal)}
						containerStyle={tw.style(
							'bg-background shadow-none border-0 bg-opacity-0'
						)}
						checkedIcon={
							<Icon name="radio-button-checked" color={tw.color('primary')} />
						}
						uncheckedIcon={
							<Icon name="radio-button-unchecked" color={tw.color('primary')} />
						}
						title={
							<View style={tw.style('flex flex-row flex-wrap flex-1 ')}>
								<Text>Ich stimme den </Text>
								<Text
									onPress={() => Linking.openURL('https://www.u-bring.de/agb')}
									style={tw.style('underline')}
								>
									AGB
								</Text>
								<Text> und der </Text>
								<Text
									onPress={() =>
										Linking.openURL('https://u-bring.de/widerrufsrecht/')
									}
									style={tw.style('underline')}
								>
									Widerrufsbelehrung
								</Text>
								<Text> zu.</Text>
							</View>
						}
					/>
					<Text>
						Du bezahlst die U-BRING Gebühr von in Höhe von 10% der
						Transportkosten im nächsten Schritt.
					</Text>
				</View>

				<View style={tw.style('flex flex-row')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						showIcon
						onPress={() => goToPayment()}
						disabled={!checkLegal || loading}
						loading={loading}
						label="Kostenpflichtig versenden"
					/>
				</View>
			</View>
		</View>
	);
}
