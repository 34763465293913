import React from 'react';
import {
	View,
	Text,
	TouchableOpacity,
	ActivityIndicator,
	Platform,
} from 'react-native';
import {Icon} from 'react-native-elements';
import {normalizeTextSize} from '../lib/normalizeFontSize';
import tw from '../lib/tailwind';

export default function NextButton({
	label = '',
	onPress,
	showIcon = false,
	disabled = false,
	loading = false,
}) {
	return (
		<View style={[tw.style('w-3/4 pl-1.5 '), {zIndex: 1}]}>
			<TouchableOpacity
				style={[
					tw.style(
						'flex-1 flex-row justify-center items-center bg-primary py-2 border border-primary rounded-xl' +
							(disabled ? ' bg-gray-400 border-gray-400' : '')
					),
					{zIndex: 1},
				]}
				onPress={onPress}
				disabled={disabled}
			>
				<View style={tw.style('flex flex-row items-center')}>
					<Text
						style={tw.style(
							`text-white text-${
								Platform.OS == 'web' ? 'lg' : 'md'
							} font-semibold text-center mr-2`
						)}
					>
						{label}
					</Text>
					{showIcon ? (
						loading ? (
							<ActivityIndicator size="small" color={tw.color('white')} />
						) : (
							<Icon name="arrow-forward" size={24} color={tw.color('white')} />
						)
					) : (
						<></>
					)}
				</View>
			</TouchableOpacity>
		</View>
	);
}
