import {
	View,
	Text,
	Image,
	TextInput,
	ImageBackground,
	TouchableWithoutFeedback,
	Keyboard,
	Platform,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import LicensePlateImage from '../../../assets/images/licenseplate.png';
import {useDispatch} from 'react-redux';
import {setLicensePlate} from '../../../slices/registerUserSlice';

export default function LicensePlate({navigation}) {
	const [licensePlateNumber, setLicensePlateNumber] = useState('');

	const [buttonDisabled, setButtonDisabled] = useState(true);

	const dispatch = useDispatch();

	useEffect(() => {
		setButtonDisabled(
			!licensePlateNumber.match(/[A-Z]{1,3}\s[A-Z]{1,2}\s[0-9]{1,4}/g)
		);
	}, [licensePlateNumber]);

	return (
		<TouchableWithoutFeedback
			onPress={() => {
				if (Platform.OS != 'web') {
					Keyboard.dismiss();
				}
			}}
			style={tw.style('flex-1')}
		>
			<View style={tw.style('flex-1 flex justify-between')}>
				<Text
					style={tw.style(
						'text-lg font-bold text-gray-700 text-center mt-7 mb-10'
					)}
				>
					Kennzeichen
				</Text>

				<View>
					<ImageBackground
						source={LicensePlateImage}
						style={tw.style('w-full flex justify-center items-center')}
						resizeMode="contain"
					>
						<TextInput
							style={tw.style(
								'w-80 pl-15 h-20 text-3xl tracking-widest text-center'
							)}
							placeholder="XX   XX   XXXX"
							onChangeText={(text) => setLicensePlateNumber(text.toUpperCase())}
							value={licensePlateNumber}
							autoCapitalize="characters"
							placeholderTextColor={tw.color('gray-300')}
							autoComplete="off"
						/>
					</ImageBackground>
					<Text style={tw.style('text-center mt-3 px-14 font-light')}>
						Die Absätze müssen durch ein Leerzeichen gekennzeichnet sein.
					</Text>
				</View>
				<View style={tw.style('flex flex-row mx-6 mb-4')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						label="Weiter"
						showIcon
						loading={false}
						disabled={buttonDisabled}
						onPress={() => {
							dispatch(setLicensePlate(licensePlateNumber));

							navigation.push('vehicleType');
						}}
					/>
				</View>
			</View>
		</TouchableWithoutFeedback>
	);
}
