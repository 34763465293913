import React, {useEffect, useState} from 'react';
import {
	View,
	Text,
	KeyboardAvoidingView,
	Platform,
	Dimensions,
} from 'react-native';
import {CheckBox, Icon} from 'react-native-elements';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectHeaderBarLayout} from '../../../slices/headerBarSlice';
import {setEmail} from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';
import axios from 'axios';

export default function EmailForm({navigation}) {
	const dispatch = useDispatch();

	const [email, setEmailState] = useState('');
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const [emailError, setEmailError] = useState(false);

	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	useEffect(() => {
		if (email.trim().length == 0) {
			return;
		}

		if (
			email
				.trim()
				.toLowerCase()
				.match(
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				)
		) {
			console.log(process.env.APIURL + 'user/testEmail', {
				email: email.trim().toLowerCase(),
			});
			axios
				.post(process.env.APIURL + 'user/testEmail', {
					email: email.trim().toLowerCase(),
				})
				.then((res) => {
					if (!res.data) {
						setButtonDisabled(false);

						setEmailError(false);
					} else {
						setButtonDisabled(true);
						setEmailError('Die E-Mail-Adresse ist bereits vergeben.');
					}
				})
				.catch((e) => {
					setButtonDisabled(false);

					setEmailError(false);
					console.log(e.response.data);
				});
		} else {
			setButtonDisabled(true);
			setEmailError('Die E-Mail-Adresse ist nicht korrekt.');
		}
	}, [email]);

	function saveEntry() {
		dispatch(setEmail(email));
		navigation.push('passwordForm');
	}

	return (
		<View
			style={tw.style('w-full h-full flex justify-between items-center px-6')}
		>
			<View style={tw.style('w-full')}>
				<Text
					style={tw.style('text-xl font-bold text-gray-700 text-center my-7')}
				>
					Wie lautet deine E-Mail-Adresse?
				</Text>
				<Input
					placeholder="E-Mail"
					width="full"
					onChangeText={(text) => setEmailState(text)}
					value={email}
					keyboardType="email-address"
					error={emailError}
				/>
			</View>
			<View>
				<View style={tw.style('flex flex-row justify-center w-full mb-6')}>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => saveEntry()}
						label="Weiter"
						showIcon
						disabled={buttonDisabled}
					/>
				</View>
			</View>
		</View>
	);
}
