import {
	View,
	Text,
	TouchableOpacity,
	Image,
	Platform,
	TouchableWithoutFeedback,
} from 'react-native';
import React, {useEffect, useRef, useState} from 'react';
import tw from '../lib/tailwind';
import {Icon} from 'react-native-elements';
import {Marker} from 'react-native-maps';
import MapViewDirections from 'react-native-maps-directions';

import MarkerFinish from '../assets/images/icons/map/Finish_MapIcon.png';
import MarkerHome from '../assets/images/icons/map/Home_MapIcon.png';
import MapView from 'react-native-maps';

export default function Accordion(props) {
	const [isOpen, setIsOpen] = useState(props.isOpen || false);

	const mapRef = useRef();

	const [mapReady, setMapReady] = useState(false);

	useEffect(() => {
		if (!props.originAddress || !props.destinationAddress || !mapReady) return;

		if (!isOpen) {
			setMapReady(false);
			return;
		}

		if (Platform.OS !== 'web') {
			mapRef.current.fitToSuppliedMarkers(['origin', 'destination'], {
				edgePadding: {
					top: 75,
					left: 60,
					bottom: 25,
					right: 60,
				},
			});
		}
	}, [mapReady, isOpen]);

	return (
		<TouchableWithoutFeedback onPress={() => {}}>
			<View style={tw.style('bg-white border-b border-gray-200')}>
				<TouchableOpacity onPress={() => setIsOpen(!isOpen)}>
					<View style={tw.style('py-3 px-4 flex flex-row justify-between')}>
						<Text
							style={tw.style('font-bold text-lg w-8/10')}
							numberOfLines={1}
						>
							{props.title}
						</Text>
						<Icon name={isOpen ? 'expand-less' : 'expand-more'} />
					</View>
				</TouchableOpacity>
				{isOpen ? (
					<View style={tw.style('flex bg-background')}>
						<View style={tw.style('px-4')}>
							<View style={tw.style('my-3 flex flex-row')}>
								<Text style={tw.style('w-21')}>Von</Text>
								<Text onPress={() => {}}>{props.originAddress.longString}</Text>
							</View>
							<View style={tw.style('my-3 flex flex-row')}>
								<Text style={tw.style('w-21')}>Nach</Text>
								<Text onPress={() => {}}>
									{props.destinationAddress.longString}
								</Text>
							</View>
						</View>
						<View style={tw.style('py-6')}></View>
						<MapView
							initialRegion={{
								latitude: parseFloat(props.originAddress.latitude),
								longitude: parseFloat(props.originAddress.longitude),
								latitudeDelta: 5,
								longitudeDelta: 5,
							}}
							loadingIndicatorColor={tw.color('primary')}
							loadingBackgroundColor={tw.color('gray-300')}
							mapType="standard"
							style={tw.style('w-full h-58')}
							ref={mapRef}
							onMapReady={() => {
								setMapReady(true);
							}}
						>
							<MapViewDirections
								origin={{
									latitude: parseFloat(props.originAddress.latitude),
									longitude: parseFloat(props.originAddress.longitude),
								}}
								destination={{
									latitude: parseFloat(props.destinationAddress.latitude),
									longitude: parseFloat(props.destinationAddress.longitude),
								}}
								apikey={process.env.GOOGLE_API_KEY}
								strokeColor={tw.color('accent')}
								strokeWidth={2}
								mode="DRIVING"
							/>

							<MapView.Marker
								coordinate={{
									latitude: parseFloat(props.originAddress.latitude),
									longitude: parseFloat(props.originAddress.longitude),
								}}
								identifier="origin"
								icon={MarkerHome}
							>
								<Image source={MarkerHome} resizeMode="contain" />
							</MapView.Marker>
							<MapView.Marker
								coordinate={{
									latitude: parseFloat(props.destinationAddress.latitude),
									longitude: parseFloat(props.destinationAddress.longitude),
								}}
								identifier="destination"
								icon={MarkerFinish}
							>
								<Image source={MarkerFinish} resizeMode="contain" />
							</MapView.Marker>
						</MapView>
						<View style={tw.style('flex flex-row justify-evenly p-4')}>
							<TouchableOpacity
								style={tw.style('bg-red-500 py-2 px-4 rounded-lg shadow-md')}
								onPress={() => {
									props.onDelete();
								}}
							>
								<Text style={tw.style(' text-white font-semibold text-lg')}>
									Löschen
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								style={tw.style('bg-primary py-2 px-4 rounded-lg shadow-md')}
								onPress={() => {
									props.onEdit();
								}}
							>
								<Text style={tw.style(' text-white font-semibold text-lg')}>
									Bearbeiten
								</Text>
							</TouchableOpacity>
							<TouchableOpacity
								style={tw.style('bg-primary py-2 px-4 rounded-lg shadow-md')}
								onPress={props.onClickPackages}
							>
								<Text style={tw.style(' text-white font-semibold text-lg')}>
									Pakete
								</Text>
							</TouchableOpacity>
						</View>
					</View>
				) : (
					<></>
				)}
			</View>
		</TouchableWithoutFeedback>
	);
}
