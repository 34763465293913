import {
	View,
	Text,
	Touchable,
	TouchableOpacity,
	Alert,
	TouchableWithoutFeedback,
	Keyboard,
	ScrollView,
	Platform,
	ActivityIndicator,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import tw from '../../../lib/tailwind';
import {Avatar, BottomSheet, Icon} from 'react-native-elements';
import {useDispatch, useSelector} from 'react-redux';
import {
	selectToken,
	selectUserInfo,
	setToken,
	setUserInformation,
} from '../../../slices/userSlice';
import Input from '../../../uiKit/input';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';
import _ from 'lodash';
import axios from 'axios';
import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
import * as FileSystem from 'expo-file-system';

import {v4} from 'uuid';
import * as ImagePicker from 'expo-image-picker';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function EditProfile({navigation}) {
	const userInfo = useSelector(selectUserInfo);

	const [updatedUserData, setUpdatedUserData] = useState(userInfo);

	const [buttonDisabled, setButtonDisabled] = useState(true);
	const [buttonLoading, setButtonLoading] = useState(false);

	const [uploadingAvatar, setUploadingAvatar] = useState(false);

	const dispatch = useDispatch();

	const token = useSelector(selectToken);

	const [openModal, setOpenModal] = useState(false);

	const [tempLocation, setTempLocation] = useState(userInfo.address.longString);

	const getFileInfo = async (fileURI) => {
		const fileInfo = await FileSystem.getInfoAsync(fileURI);
		return fileInfo;
	};

	function updateUser(key, value) {
		var tempUserData = updatedUserData;

		if (value === '') {
			setUpdatedUserData({...tempUserData, [key]: userInfo[key]});
			return;
		}

		setUpdatedUserData({...tempUserData, [key]: value});
	}

	useEffect(() => {
		setTempLocation(userInfo.address.longString);
	}, [userInfo]);

	useEffect(() => {
		setButtonDisabled(_.isEqual(updatedUserData, userInfo));
	}, [updatedUserData]);

	async function updateUserProfile(type) {
		let result;
		try {
			if (type === 'camera') {
				let permRes = await ImagePicker.getCameraPermissionsAsync();
				if (permRes.granted === false) {
					permRes = await ImagePicker.requestCameraPermissionsAsync();
					if (permRes.granted === false) {
						Alert.alert(
							'Fehlende Berechtigung',
							'Bitte erlaube der App den Zugriff auf die Kamera'
						);
						return;
					}
				}
				result = await ImagePicker.launchCameraAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [1, 1],
					quality: 0,
				});
			} else {
				let permRes = await ImagePicker.getMediaLibraryPermissionsAsync();
				if (permRes.granted === false) {
					permRes = await ImagePicker.requestMediaLibraryPermissionsAsync();
					if (permRes.granted === false) {
						Alert.alert(
							'Fehlende Berechtigung',
							'Bitte erlaube der App den Zugriff auf die Medien'
						);
						return;
					}
				}
				result = await ImagePicker.launchImageLibraryAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [1, 1],
					quality: 0,
				});
			}
		} catch (error) {
			console.log(error);
		}

		if (!result.cancelled) {
			setUploadingAvatar(true);
			let formdata = new FormData();

			let uri = result.uri;
			let fileExtension = uri.substr(uri.lastIndexOf('.') + 1);
			//console.log(fileExtension);
			//console.log((await getFileInfo(uri)).size);

			let img_to_upload = {
				type: 'image/' + fileExtension,
				name: 'userPhoto-' + userInfo.id + '.' + fileExtension,
				uri: result.uri,
			};

			formdata.append('avatar', img_to_upload);
			//formdata.append('id', userInfo.id);

			axios
				.post(process.env.APIURL + 'user/avatar', formdata, {
					headers: {
						'Authorization': 'Bearer ' + token,
						'Content-Type': 'multipart/form-data',
					},
					transformRequest: (formdata) => formdata,
				})
				.then((res) => {
					//console.log(res.data);
					dispatch(setUserInformation(res.data.data));
					setUploadingAvatar(false);
				})
				.catch((err) => {
					console.warn(err.response.data);
					setUploadingAvatar(false);
				});
		}
	}

	function saveUpdatedData() {
		setButtonLoading(true);
		axios
			.post(process.env.APIURL + 'user/update', updatedUserData, {
				headers: {'Authorization': `Bearer ${token}`},
			})
			.then((res) => {
				if (res.data) {
					//console.log(res.data);
					dispatch(setUserInformation(res.data.data));

					Alert.alert(
						'Erfolgreich',
						'Deine Daten wurden erfolgreich geändert.',
						[{text: 'OK', onPress: () => navigation.goBack()}]
					);
				}
				setButtonLoading(false);
			})
			.catch((e) => {
				//console.log(e);
				setButtonLoading(false);
				Alert.alert(
					'Fehler',
					e.response.data ||
						'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
				);
			});
	}

	function deleteAccount() {
		Alert.alert(
			'Account löschen',
			'Bist du dir sicher, das du dein Account löschen möchtest?',
			[
				{text: 'Abbrechen', style: 'cancel'},
				{
					text: 'Löschen!',
					style: 'destructive',
					onPress: () => deleteUser(),
				},
			]
		);
	}

	async function handleLogout() {
		dispatch(setToken(null));
		let testForPersistedUser = await AsyncStorage.getItem('@ubring_login');

		if (testForPersistedUser) {
			await AsyncStorage.removeItem('@ubring_login');
		}

		navigation.navigate('start', {merge: true});
		Alert.alert(
			'Erfolgreich',
			'Dein Account wurde erfolgreich gelöscht und wir leiden sehr unter deinem Verlust.',
			[{text: 'Okay', style: 'default'}]
		);
	}

	function deleteUser() {
		axios
			.post(
				process.env.APIURL + 'user/remove',
				{},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			)
			.then((res) => {
				if (res.data) {
					//console.log(res.data);
					handleLogout();
				}
			})
			.catch((e) => {
				//console.log(e.response.data);
				Alert.alert(
					'Fehler',
					e.response.data.message ||
						'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
				);
			});
	}

	return (
		<>
			<BottomSheet modalProps={{}} isVisible={openModal}>
				<TouchableWithoutFeedback
					onPress={() => {
						updateUserProfile('libary');
						setOpenModal(false);
					}}
				>
					<View
						style={tw.style(
							'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
						)}
					>
						<Icon name="collections" />
						<Text style={tw.style('ml-3')}>Bild auswählen</Text>
					</View>
				</TouchableWithoutFeedback>
				<TouchableWithoutFeedback
					onPress={() => {
						updateUserProfile('camera');
						setOpenModal(false);
					}}
				>
					<View
						style={tw.style(
							'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
						)}
					>
						<Icon name="camera" />
						<Text style={tw.style('ml-3')}>Bild aufnehmen</Text>
					</View>
				</TouchableWithoutFeedback>
				<TouchableWithoutFeedback onPress={() => setOpenModal(false)}>
					<View
						style={tw.style(
							'w-full bg-white border-t border-gray-300 py-4 px-6 flex flex-row items-center'
						)}
					>
						<Icon name="close" />
						<Text style={tw.style('ml-3')}>Schließen</Text>
					</View>
				</TouchableWithoutFeedback>
			</BottomSheet>
			<KeyboardAwareScrollView
				style={tw.style('flex-1')}
				enableAutomaticScroll
				enableOnAndroid
				keyboardShouldPersistTaps="handled"
				nestedScrollEnabled={true}
			>
				<TouchableWithoutFeedback
					onPress={() => {
						if (Platform.OS != 'web') {
							Keyboard.dismiss();
						}
					}}
					style={tw.style('flex-1')}
				>
					<SafeAreaView
						edges={['left', 'right']}
						style={tw.style('flex-1 px-6 items-center')}
					>
						<Avatar
							source={{
								uri:
									userInfo?.avatarSrc !== ''
										? userInfo.avatarSrc
										: 'https://api.multiavatar.com/' + userInfo.email + '.png',
							}}
							size="xlarge"
							containerStyle={tw.style('bg-gray-400 mt-10 mb-7 relative')}
							rounded
							onPress={() =>
								Platform.OS !== 'ios'
									? updateUserProfile('libary')
									: setOpenModal(true)
							}
						>
							{uploadingAvatar && (
								<View
									style={tw.style(
										'absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center'
									)}
								>
									<ActivityIndicator size={'large'} color={tw.color('white')} />
								</View>
							)}
						</Avatar>
						<Input
							placeholder={userInfo.firstname}
							onChangeText={(text) => updateUser('firstname', text)}
						/>
						<Input
							placeholder={userInfo.lastname}
							onChangeText={(text) => updateUser('lastname', text)}
						/>
						<Input
							placeholder={userInfo.email}
							onChangeText={(text) => updateUser('email', text)}
						/>
						<Input
							placeholder={userInfo.telephone}
							onChangeText={(text) => updateUser('telephone', text)}
						/>

						<View style={[tw.style('w-full'), {zIndex: 99}]}>
							<GooglePlacesAutocomplete
								requestUrl={{
									useOnPlatform: 'web', // or "all"
									url: 'https://maps.googleapis.com/maps/api', // or any proxy server that hits https://maps.googleapis.com/maps/api
									headers: {
										Authorization: `an auth token`, // if required for your proxy
									},
								}}
								styles={{
									'textInput': tw.style(''),
									'textInputContainer': tw.style(
										'bg-white rounded-xl shadow-md py-4 px-5 relative'
									),
									'listView': tw.style('mt-1 rounded-xl mb-2 absolute top-13'),
									'row': tw.style('bg-white p-3 px-2 '),
									'separator': tw.style('bg-gray-300 h-.1'),
									'container': {zIndex: 999},
								}}
								textInputProps={{
									placeholderTextColor: tw.color('black'),
									returnKeyType: 'search',
									value: tempLocation,
									onChangeText: (event) => setTempLocation(event),
								}}
								placeholder={userInfo.address.longString || 'Adresse'}
								nearbyPlacesAPI="GooglePlacesSearch"
								debounce={400}
								enablePoweredByContainer={false}
								query={{
									key: process.env.GOOGLE_API_KEY,
									language: 'de',
									components: 'country:de',
								}}
								minLength={2}
								fetchDetails={true}
								returnKeyType="Suchen"
								suppressDefaultStyles
								onPress={(data, details = null) => {
									var houseNumber = 0;
									var streetName = '';
									var postal = 0;
									var city = '';
									var state = '';

									details.address_components.map(({types, long_name}) => {
										switch (types[0]) {
											case 'street_number':
												houseNumber = long_name;
												break;

											case 'route':
												streetName = long_name;
												break;

											case 'administrative_area_level_1':
												state = long_name;
												break;

											case 'postal_code':
												postal = long_name;
												break;

											case 'locality':
												city = long_name;
												break;

											default:
												break;
										}
									});

									setTempLocation(
										streetName +
											' ' +
											houseNumber +
											', ' +
											postal +
											' ' +
											city +
											', ' +
											state
									);
									updateUser('address', {
										street: streetName,
										number: houseNumber,
										postal: postal,
										city: city,
										state: state,
										latitude: details.geometry.location.lat,
										longitude: details.geometry.location.lng,
										shortString: streetName + ' ' + houseNumber,
										longString:
											streetName +
											' ' +
											houseNumber +
											', ' +
											postal +
											' ' +
											city +
											', ' +
											state,
										placeID: v4(),
									});
								}}
							/>
							<Text
								style={[
									tw.style('mt-2 font-light text-sm text-gray-900'),
									{zIndex: 1},
								]}
							>
								Um eine Adresse zu nutzen, wähle einen der Vorschläge aus. Bitte
								Adresse mit Straße, Hausnummer und Ort eintragen
							</Text>
						</View>

						<View style={tw.style('flex flex-row justify-between mt-6 w-full')}>
							<BackButton onPress={() => navigation.goBack()} />
							<NextButton
								onPress={() => saveUpdatedData()}
								label="Speichern"
								disabled={buttonDisabled}
								loading={buttonLoading}
							/>
						</View>
						<TouchableOpacity
							onPress={() => deleteAccount()}
							style={tw.style('flex flex-row items-center my-12')}
						>
							<Icon name="delete" color={tw.color('red-500')} />
							<Text style={tw.style('text-red-500')}>Account löschen</Text>
						</TouchableOpacity>
					</SafeAreaView>
				</TouchableWithoutFeedback>
			</KeyboardAwareScrollView>
		</>
	);
}
