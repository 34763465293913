import {
	View,
	Text,
	Image,
	TouchableOpacity,
	ScrollView,
	TouchableWithoutFeedback,
} from 'react-native';
import React, {useState} from 'react';
import tw from '../../../lib/tailwind';

import Placeholder from '../../../assets/images/icons/image_placeholder.png';
import Trash from '../../../assets/images/icons/trash-icon.png';
import Camera from '../../../assets/images/icons/camera-icon.png';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import * as ImagePicker from 'expo-image-picker';
import {useRef} from 'react';
import {useDispatch} from 'react-redux';
import {setLicenseData} from '../../../slices/registerUserSlice';

export default function UploadDriverLicense({navigation}) {
	const [imageUri, setImageUri] = useState(null);
	const [imageType, setImageType] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [imageViewSize, setImageViewHeight] = useState(0);

	const [imageRes, setImageRes] = useState(null);

	const [imageViewWidth, setImageViewWidth] = useState(0);

	const [buttonDisabled, setButtonDisabled] = useState(true);

	let dispatch = useDispatch();

	async function handleUploadImage(type) {
		let result;

		try {
			if (type === 'camera') {
				await ImagePicker.requestCameraPermissionsAsync();
				result = await ImagePicker.launchCameraAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [43, 27],
					quality: 1,
				});
			} else {
				result = await ImagePicker.launchImageLibraryAsync({
					mediaTypes: ImagePicker.MediaTypeOptions.Images,
					allowsEditing: true,
					aspect: [43, 27],
					quality: 1,
				});
			}
		} catch (error) {
			console.log(error);
		}

		if (!result.canceled) {
			setImageType(result.assets[0].type);
			setImageName(result.assets[0].fileName);

			setImageRes(result.assets[0]);

			let ratio = imageViewWidth / result.assets[0].width;
			let height = result.assets[0].height * ratio;

			setImageViewHeight(height);

			setImageUri(result.assets[0].uri);

			setButtonDisabled(false);
		}

		// if (!result.cancelled) {
		// 	setImageType(result.type);
		// 	setImageName(result.fileName);

		// 	setImageRes(result);

		// 	let ratio = imageViewWidth / result.width;
		// 	let height = result.height * ratio;

		// 	setImageViewHeight(height);

		// 	setImageUri(result.uri);

		// 	setButtonDisabled(false);
		// }

		console.log(result);
	}

	return (
		<TouchableWithoutFeedback onPress={() => {}}>
			<ScrollView style={tw.style('flex-1')}>
				<View>
					<Text
						style={tw.style(
							'text-lg font-bold text-gray-700 text-center mt-7 mb-10'
						)}
					>
						Foto - Führerschein
					</Text>
					<View style={tw.style('flex-1 flex justify-between items-center')}>
						<View
							style={tw.style(
								'bg-gray-300 rounded-md w-8/10 max-h-2/3 flex items-center'
							)}
							onLayout={(event) => {
								var {x, y, width, height} = event.nativeEvent.layout;
								setImageViewWidth(width);
							}}
						>
							{imageUri ? (
								<Image
									source={{uri: imageUri}}
									style={tw.style(
										'w-full h-[' + imageViewSize + 'px] max-h-1/1 rounded-xl'
									)}
									resizeMethod="scale"
									resizeMode="contain"
								/>
							) : (
								<Image
									source={Placeholder}
									style={tw.style('w-20 h-20 m-15')}
									resizeMethod="scale"
									resizeMode="contain"
								/>
							)}
						</View>
						<View style={tw.style('w-full px-12')}>
							<View style={tw.style('flex flex-row justify-evenly my-6 ')}>
								<TouchableOpacity onPress={() => handleUploadImage('camera')}>
									<View style={tw.style('flex justify-center items-center')}>
										<View
											style={tw.style(
												'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
											)}
										>
											<Image
												source={Camera}
												style={tw.style('w-8 h-8')}
												resizeMode="contain"
											/>
										</View>
										<Text style={tw.style('my-2 font-light')}>Aufnehmen</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity onPress={() => handleUploadImage('libary')}>
									<View style={tw.style('flex justify-center items-center')}>
										<View
											style={tw.style(
												'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
											)}
										>
											<Image
												source={Placeholder}
												style={tw.style('w-8 h-8')}
												resizeMode="contain"
											/>
										</View>
										<Text style={tw.style('my-2 font-light')}>Auswählen</Text>
									</View>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										setImageName(null);
										setImageUri(null);
										setImageType(null);
										setImageViewHeight(0);
										setButtonDisabled(true);
									}}
								>
									<View style={tw.style('flex justify-center items-center')}>
										<View
											style={tw.style(
												'bg-primary rounded-lg shadow-md h-12 w-12 flex justify-center items-center'
											)}
										>
											<Image
												source={Trash}
												style={tw.style('w-8 h-8')}
												resizeMode="contain"
											/>
										</View>
										<Text style={tw.style('my-2 font-light')}>Löschen</Text>
									</View>
								</TouchableOpacity>
							</View>
							<Text style={tw.style('mb-4 text-gray-400 text-center')}>
								Die Daten werden geprüft und nach DSGVO vertraulich behandelt.
								Einwilligung zur Datenerfassung
							</Text>
						</View>
					</View>
					<View style={tw.style('flex flex-row mx-6 mb-4')}>
						<BackButton onPress={() => navigation.goBack()} />
						<NextButton
							label="Weiter"
							showIcon
							loading={false}
							disabled={buttonDisabled}
							onPress={() => {
								dispatch(setLicenseData({image: imageRes}));
								navigation.push('licensePlate');
							}}
						/>
					</View>
				</View>
			</ScrollView>
		</TouchableWithoutFeedback>
	);
}
