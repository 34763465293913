import axios from 'axios';
import React, {useState} from 'react';
import {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectToken, selectUserInfo} from '../../../slices/userSlice';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

function Payment({navigation, route}) {
	const token = useSelector(selectToken);

	const params = route.params;

	const bringerData = params.bringerData;
	const packageData = params.packageData;

	const userInfo = useSelector(selectUserInfo);

	const [loading, setLoading] = useState(false);

	function acceptRequest() {
		setLoading(true);
	}

	return (
		<View style={tw.style('flex-1 flex justify-between p-6')}>
			<View>
				<Text>
					Die Zahlfunktion ist für Testzwecke noch nicht implementiert.
				</Text>
			</View>
			<View>
				<View style={tw.style('flex flex-row')}>
					<BackButton onPress={() => {}} hidden />
					<NextButton
						showIcon
						onPress={() => acceptRequest()}
						disabled={loading}
						loading={loading}
						label="Bezahlen"
					/>
				</View>
			</View>
		</View>
	);
}

export default Payment;
