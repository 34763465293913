import axios from 'axios';
import React, {useEffect} from 'react';
import {Rating} from 'react-native-elements';
import {useSelector} from 'react-redux';
import tw from '../lib/tailwind';
import {selectToken} from '../slices/userSlice';

export default function Ratings({userID}) {
	const token = useSelector(selectToken);

	const [rating, setRating] = React.useState('0');

	useEffect(() => {
		getMyRating();
	}, []);

	async function getMyRating() {
		try {
			let res = await axios.patch(
				process.env.APIURL + 'user/rating',
				{
					recipientID: userID,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);
			setRating(res.data.rating);
			console.log(res.data);
		} catch (error) {
			console.log(error.response);
		}
	}

	return (
		<Rating
			imageSize={20}
			readonly
			startingValue={rating}
			tintColor={tw.color('background')}
			ratingBackgroundColor={tw.color('background')}
		/>
	);
}
