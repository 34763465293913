import {View, Text, ActivityIndicator} from 'react-native';
import React from 'react';
import tw from '../lib/tailwind';

export default function LoadingScreen() {
	return (
		<View style={tw.style('flex-1 justify-center items-center')}>
			<View>
				<ActivityIndicator size={'large'} style={tw.style('mb-4')} />
				<Text>Laden...</Text>
			</View>
		</View>
	);
}
