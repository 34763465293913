import {View, Text, TouchableWithoutFeedback, Keyboard} from 'react-native';
import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import EditProfile from './editProfile';
import UAndMe from './u-andme';
import Statistics from './statistics';
import ViewRequests from './viewRequests';
import ViewReviews from './viewReviews';
import ViewBringerData from './viewBringerData';
import ViewTransportVerträge from './viewTransportVertrage';
import ViewInvoices from './viewInvoices';

export default function IndexUandMe() {
	const Stack = createNativeStackNavigator();

	return (
		<Stack.Navigator
			screenOptions={{headerShown: false}}
			initialRouteName="main"
		>
			<Stack.Screen name="main" component={UAndMe} />
			<Stack.Screen name="editProfile" component={EditProfile} />
			<Stack.Screen name="statistics" component={Statistics} />
			<Stack.Screen name="viewRequests" component={ViewRequests} />
			<Stack.Screen name="viewReviews" component={ViewReviews} />
			<Stack.Screen name="viewInvoices" component={ViewInvoices} />
			<Stack.Screen name="bringerData" component={ViewBringerData} />
			<Stack.Screen
				name="viewTransportVertrage"
				component={ViewTransportVerträge}
			/>
		</Stack.Navigator>
	);
}
