import {View, Text, ScrollView} from 'react-native';
import React, {useEffect, useState} from 'react';
import {selectToken, selectUserInfo} from '../../../slices/userSlice';
import {useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import axios from 'axios';
import {Rating} from 'react-native-elements';
import BackButton from '../../../uiKit/backButton';
import {useNavigation} from '@react-navigation/native';

export default function ViewReviews() {
	const token = useSelector(selectToken);
	const userInfo = useSelector(selectUserInfo);

	const navigation = useNavigation();

	const [reviews, setReviews] = useState(null);

	useEffect(() => {
		getAllReviews();
	}, []);

	async function getAllReviews() {
		try {
			let res = await axios.get(process.env.APIURL + 'user/ratings', {
				headers: {'Authorization': `Bearer ${token}`},
			});

			if (res.data) {
				setReviews(res.data);
			}

			console.log(res.data);
		} catch (error) {
			console.log(error.response.data);
		}
	}

	return (
		<ScrollView contentContainerStyle={tw.style('p-6')}>
			<View style={tw.style('flex flex-row justify-between mb-6')}>
				<BackButton onPress={() => navigation.goBack()} />
				<Text style={tw.style('text-xl font-bold text-center w-1/2')}>
					Alle Bewertungen
				</Text>
				<View style={tw.style('w-1/4')} />
			</View>
			{reviews &&
				reviews.map((review) => {
					return (
						<View
							style={tw.style('bg-white rounded-lg p-4 mb-4')}
							key={review.comment}
						>
							<View style={tw.style('flex-row ')}>
								<Rating
									imageSize={20}
									readonly
									startingValue={review.rating}
									tintColor={tw.color('white')}
									ratingBackgroundColor={tw.color('white')}
								/>
							</View>
							<Text style={tw.style('mt-4')}>
								{review.comment == '' ? 'Ohne Kommentar' : review.comment}
							</Text>
						</View>
					);
				})}
			{!reviews ||
				(reviews.length == 0 && (
					<Text style={tw.style('text-center')}>
						Keine Bewertungen vorhanden
					</Text>
				))}
		</ScrollView>
	);
}
