import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Linking,
	Share,
	Platform,
} from 'react-native';
import React, {useState} from 'react';
import tw from '../../../lib/tailwind';
import {useEffect} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';
import BackButton from '../../../uiKit/backButton';
import dateFormat from 'dateformat';
import {Icon} from 'react-native-elements';
import {useNavigation} from '@react-navigation/native';

import * as Browser from 'expo-web-browser';
import * as IntentLauncher from 'expo-intent-launcher';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';

export default function ViewInvoices() {
	const token = useSelector(selectToken);

	const navigation = useNavigation();

	const [requests, setRequests] = useState([]);

	useEffect(() => {
		getInvoices();
	}, []);

	async function getInvoices() {
		try {
			let res = await axios.get(process.env.APIURL + 'shipment/invoices', {
				headers: {'Authorization': `Bearer ${token}`},
			});

			if (res.data) {
				setRequests(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async function openInvoice(invoice) {
		try {
			// Browser.openBrowserAsync(
			// 	process.env.APIURL + 'shipmentDoc/' + invoice.invoiceFile
			// );

			// Download file to local storage under name RE-<invoiceID>.pdf and folder U-BRING

			if (Platform.OS == 'web') {
				const aElement = document.createElement('a');
				const href = process.env.APIURL + 'shipmentDoc/' + invoice.invoiceFile;

				aElement.href = href;
				aElement.download = 'RE-' + invoice.id + '.pdf';
				document.body.appendChild(aElement);
				aElement.click();
				document.body.removeChild(aElement);

				return;
			}

			FileSystem.downloadAsync(
				process.env.APIURL + 'shipmentDoc/' + invoice.invoiceFile,
				FileSystem.documentDirectory +
					(invoice.invoiceFile || 'RE-' + invoice.id + '.pdf')
			)
				.then(({uri}) => {
					FileSystem.getContentUriAsync(uri).then((cUri) => {
						if (Platform.OS == 'ios') {
							Sharing.shareAsync(cUri);
						} else {
							IntentLauncher.startActivityAsync('android.intent.action.VIEW', {
								data: cUri,
								flags: 1,
								type: 'application/pdf',
							});
						}
					});
				})
				.catch((error) => {
					console.error(error);
				});
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<ScrollView contentContainerStyle={tw.style('p-6')}>
			<View style={tw.style('flex flex-row justify-between mb-6')}>
				<BackButton onPress={() => navigation.goBack()} />
				<Text style={tw.style('text-xl font-bold text-center w-2/4')}>
					Meine Rechnungen
				</Text>
				<View style={tw.style('w-1/4')} />
			</View>
			{requests.map((request) => {
				return (
					<View
						style={tw.style(
							'bg-white rounded-lg p-4 mb-4 flex flex-row justify-between items-center'
						)}
						key={request.id}
					>
						<View>
							<Text style={tw.style('text-lg font-bold')}>
								Paket:
								{' ' +
									request.name +
									' (' +
									(request.invoice?.invoiceFile ||
										'R.Nr.:' + request.invoice?.id) +
									')'}
							</Text>
							<Text style={tw.style('text-base font-thin')}>
								{dateFormat(new Date().setTime(request.date), 'dd.mm.yyyy')}
							</Text>
						</View>
						<TouchableOpacity
							onPress={() => {
								openInvoice(request.invoice);
							}}
						>
							<Icon
								name="chevron-right"
								type="material"
								color={tw.color('gray-600')}
							/>
						</TouchableOpacity>
					</View>
				);
			})}
		</ScrollView>
	);
}
