import {
	View,
	Text,
	SafeAreaView,
	ScrollView,
	FlatList,
	TouchableOpacity,
	Image,
	AppState,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import tw from '../../../lib/tailwind';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken, selectUserInfo} from '../../../slices/userSlice';
import PackageProgressIndicator from '../../../assets/images/icons/package/packageProgressIndicator';
import {Avatar, Icon} from 'react-native-elements';

import {useIsFocused} from '@react-navigation/native';

export default function MainUMission({navigation}) {
	const [shipments, setShipments] = useState(null);

	const token = useSelector(selectToken);

	const userInfo = useSelector(selectUserInfo);
	const isFocused = useIsFocused();

	useEffect(() => {
		navigation.addListener('focus', () => {
			getShipments();
		});

		let sub = AppState.addEventListener('change', () => {
			getShipments();
		});

		return () => {
			navigation.removeListener('focus');
			sub.remove();
		};
	}, [navigation]);

	useEffect(() => {
		getShipments();
	}, [token, isFocused]);

	function getShipments() {
		axios
			.get(process.env.APIURL + 'shipments', {
				headers: {'Authorization': `Bearer ${token}`},
			})
			.then((res) => {
				//console.log(res);
				if (res.data) {
					const temp = res.data.data;

					// console.log(res.data.data);

					setShipments([...temp]);
				}
			})
			.catch((e) => console.log(e.response));
	}

	return (
		<View style={tw.style('flex-1')}>
			<Text style={tw.style('text-center text-lg font-semibold py-4')}>
				Aktuelle Sendungen
			</Text>

			{shipments && (
				<FlatList
					data={shipments}
					keyExtractor={(item) => item.data.id}
					showsVerticalScrollIndicator={false}
					renderItem={({item, index}) => {
						var packageRelation = 'SENDER';
						var relationColor = 'accent';

						if (item.data.recipient.toLowerCase() === userInfo.email) {
							packageRelation = 'EMPFÄNGER';
							relationColor = 'gray-400';
						} else if (
							item.data.recipient.toLowerCase() !== userInfo.email &&
							parseInt(item.data.userId) !== userInfo.id
						) {
							packageRelation = 'BRINGER';
							relationColor = 'primary';
						}
						var formatedPrice = item.data.price / 100;
						formatedPrice = formatedPrice.toFixed(2).replace('.', ',') + ' €';

						var requests = item.requests;

						let acceptedRequestData = requests.find(
							(filterItem) => item.data.accepted_request_id === filterItem.id
						);

						var currentStatus = 0;

						if (acceptedRequestData) {
							currentStatus =
								(acceptedRequestData.bringer_id != null ? 1 : 0) +
								parseInt(acceptedRequestData.sender_handed_over) +
								parseInt(acceptedRequestData.recipient_recieved_from_bringer) +
								parseInt(acceptedRequestData.sender_payed);
						}

						//console.log(currentStatus);

						return (
							<TouchableOpacity
								onPress={() => {
									if (userInfo.email === item.data.recipient) {
										navigation.navigate('liveTracking', {
											data: item,
										});
									} else if (
										userInfo.email !== item.data.recipient &&
										parseInt(item.data.userId) !== userInfo.id
									) {
										if (
											parseInt(
												acceptedRequestData.bringer_recieved_from_sender
											) === 0
										) {
											navigation.navigate('packageDetailBringer', {
												data: item,
											});
										} else {
											navigation.navigate('liveTracking', {
												data: item,
											});
										}
									} else if (!acceptedRequestData) {
										navigation.navigate('packageDetail', {
											selectedPackage: item.data.id,
										});
									} else {
										if (acceptedRequestData && currentStatus === 0) {
											navigation.navigate('senderDetails', {
												data: item,
											});
										} else if (acceptedRequestData && currentStatus > 0) {
											navigation.navigate('liveTracking', {
												data: item,
											});
										} else if (acceptedRequestData && currentStatus === 4) {
											navigation.navigate('senderDetails', {
												data: item,
											});
										}
									}
								}}
								key={item.data.id}
							>
								<View
									style={tw.style(
										'bg-white rounded-lg shadow-md my-3 mx-6 pr-4 flex flex-row justify-between items-center overflow-hidden'
									)}
								>
									<View style={tw.style('flex flex-row items-center flex-3')}>
										<Image
											source={{
												uri:
													item.data.imageSrc ||
													'https://images.unsplash.com/photo-1577705998148-6da4f3963bc8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80',
											}}
											style={[
												tw.style('w-22 h-22 rounded-lg'),
												{aspectRatio: 1},
											]}
										/>
										<View style={tw.style('ml-4 p-4 flex-1 ')}>
											<Text
												style={tw.style('text-' + relationColor + ' font-bold')}
											>
												{packageRelation}
											</Text>
											<Text
												style={tw.style('text-lg font-semibold')}
												numberOfLines={1}
											>
												{item.data.name}
											</Text>
											<Text>{formatedPrice}</Text>
										</View>
									</View>

									<View
										style={tw.style(
											'flex flex-row items-center flex-1 justify-end'
										)}
									>
										{item.requests.length === 0 || acceptedRequestData ? (
											<></>
										) : (
											<>
												<Text>{item.requests.length}</Text>

												<Icon
													name="notifications-active"
													color={tw.color('accent')}
													containerStyle={tw.style('mr-4 ml-1')}
												/>
											</>
										)}

										<PackageProgressIndicator progress={currentStatus} />
									</View>
								</View>
							</TouchableOpacity>
						);
					}}
				/>
			)}

			{!shipments && (
				<View style={tw.style('flex justify-center items-center')}>
					<Text style={tw.style('text-base font-light')}>
						Du hast noch keine Pakete
					</Text>
				</View>
			)}
		</View>
	);
}
