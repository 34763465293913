import {
	View,
	Text,
	TouchableWithoutFeedback,
	Platform,
	TouchableOpacity,
	KeyboardAvoidingView,
	Keyboard,
	Alert,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import HeaderBar from '../../uiKit/headerBar';
import tw from '../../lib/tailwind';
import Input from '../../uiKit/input';
import {useSelector} from 'react-redux';
import {selectHeaderBarLayout} from '../../slices/headerBarSlice';
import BackButton from '../../uiKit/backButton';
import NextButton from '../../uiKit/nextButton';
import axios from 'axios';

export default function RequestPasswordReset({navigation}) {
	const [email, setEmail] = useState('');

	const [buttonLoading, setButtonLoading] = useState(false);

	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	function requestReset() {
		setButtonLoading(true);
		axios
			.post(
				process.env.APIURL + 'user/request',
				{email: email.trim()},
				{
					'headers': {
						'content-type': 'application/json',
					},
				}
			)
			.then((res) => {
				//console.log(res.data);
				navigation.goBack();
				setButtonLoading(false);

				Alert.alert(
					'E-Mail gesendet',
					'Es wurde eine E-Mail mit dem Link zum Zurücksetzen des Passwortes an deine E-Mail geschickt.'
				);
			})
			.catch((e) => {
				//console.log(e);
				Alert.alert(
					'Fehler',
					'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
				);
				setButtonLoading(false);
			});
	}

	return (
		<TouchableWithoutFeedback
			onPress={() => {
				if (Platform.OS != 'web') {
					Keyboard.dismiss();
				}
			}}
		>
			<View style={tw.style('flex-1 bg-background')}>
				<HeaderBar siteTitle="U-Login" />
				<View style={tw.style('flex-1 justify-between px-6')}>
					<View style={tw.style('')}>
						<Text style={tw.style('font-bold text-xl my-7 text-center')}>
							Link zum zurücksetzen senden
						</Text>
						<Input
							onChangeText={(text) => setEmail(text)}
							value={email}
							accessibilityLabel="E-Mail eingeben"
							accessibilityRole="text"
							placeholder="E-Mail"
							autoCapitalize="none"
							keyboardType="email-address"
						/>
					</View>

					<KeyboardAvoidingView
						behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
						keyboardVerticalOffset={HeaderBarLayout.height + 14}
					>
						<View style={tw.style('flex flex-row justify-center w-full mb-4')}>
							<BackButton onPress={() => navigation.goBack()} />
							<NextButton
								onPress={() => requestReset()}
								label="Zurücksetzen"
								showIcon={buttonLoading}
								loading={buttonLoading}
								disabled={buttonLoading}
							/>
						</View>
					</KeyboardAvoidingView>
				</View>
			</View>
		</TouchableWithoutFeedback>
	);
}
