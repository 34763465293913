import {
	View,
	Text,
	TouchableWithoutFeedback,
	Keyboard,
	Platform,
	ScrollView,
} from 'react-native';
import React from 'react';
import UploadDriverLicense from './uploadDriverLicense';
import LicensePlate from './licensePlate';
import VehicleType from './vehicleType';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import HeaderBar from '../../../uiKit/headerBar';
import tw from '../../../lib/tailwind';
import ThankYouRegisterBring from './thank_you';

export default function RegisterUbring() {
	const Stack = createNativeStackNavigator();
	return (
		<View style={tw.style('flex-1')}>
			<HeaderBar siteTitle="U-Register" />

			<Stack.Navigator
				screenOptions={{headerShown: false}}
				initialRouteName="uploadDriverLicense"
			>
				<Stack.Screen
					name="uploadDriverLicense"
					component={UploadDriverLicense}
				/>
				<Stack.Screen name="licensePlate" component={LicensePlate} />
				<Stack.Screen name="vehicleType" component={VehicleType} />
				<Stack.Screen name="thankYou" component={ThankYouRegisterBring} />
			</Stack.Navigator>
		</View>
	);
}
