import React from 'react';
import {Text, TextInput, View} from 'react-native';
import tw from '../lib/tailwind';

export default function Input({
	placeholder = '',
	onChangeText,
	value,
	width = 'full',
	accessibilityLabel,
	accessibilityRole,
	autoCapitalize = 'none',
	secureTextEntry = false,
	keyboardType = 'default',
	type,
	disabled = false,
	textCenter,
	textColor = 'black',
	onContentSizeChange,
	onPress,
	preText,
	error,
	onEndEditing = () => {},
}) {
	return (
		<View style={tw.style(' w-' + width)} removeClippedSubviews={false}>
			<View
				style={tw.style(
					'flex-row items-center bg-white rounded-xl shadow-md mb-4' +
						(error ? ' border border-red-500' : '')
				)}
				removeClippedSubviews={false}
			>
				{type === 'phone' ? (
					<Text style={tw.style('ml-4 mr--2 font-semibold text-3xl')}>🇩🇪</Text>
				) : (
					preText && (
						<Text style={tw.style('text-xl ml-3 text-primary')}>{preText}</Text>
					)
				)}
				<TextInput
					style={tw.style(
						'flex-1 h-full py-4 px-3 ' +
							(textCenter ? 'text-center ' : '') +
							'text-' +
							textColor
					)}
					onChangeText={onChangeText}
					value={value}
					clearButtonMode="while-editing"
					placeholder={placeholder}
					placeholderTextColor={tw.color('black')}
					autoCapitalize={autoCapitalize}
					secureTextEntry={secureTextEntry}
					keyboardType={keyboardType}
					maxLength={256}
					editable={!disabled}
					selectTextOnFocus={true}
					pointerEvents={disabled ? 'none' : 'auto'}
					onEndEditing={onEndEditing}
				/>
			</View>
			{error && (
				<Text style={tw.style('text-red-500 text-sm ml-4 -mt-3 mb-3')}>
					{error}
				</Text>
			)}
		</View>
	);
}
