import {View, Text, TouchableWithoutFeedback, Keyboard} from 'react-native';
import React from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import MainUMission from './main';
import PackageDetail from './packageDetail';
import AcceptBringer from './acceptBringer';
import ConfirmHandOver from './confirmHandOver';
import SenderDetails from './senderDetails';
import LiveTracking from './liveTracking';
import BringerPackageDetail from './bringerPackageDetail';
import WaitingForBringer from './waitingForBringer';
import Payment from './payment';
import ViewBringer from './viewBringer';

export default function IndexUMission() {
	const Stack = createNativeStackNavigator();
	return (
		<Stack.Navigator
			screenOptions={{headerShown: false}}
			initialRouteName="main"
		>
			<Stack.Screen name="main" component={MainUMission} />
			<Stack.Screen name="packageDetail" component={PackageDetail} />
			<Stack.Screen name="acceptBringer" component={AcceptBringer} />
			<Stack.Screen name="confirmHandOver" component={ConfirmHandOver} />
			<Stack.Screen name="senderDetails" component={SenderDetails} />
			<Stack.Screen name="payment" component={Payment} />
			<Stack.Screen name="ViewBringer" component={ViewBringer} />
			<Stack.Screen name="liveTracking" component={LiveTracking} />
			<Stack.Screen name="waitingForBringer" component={WaitingForBringer} />
			<Stack.Screen
				name="packageDetailBringer"
				component={BringerPackageDetail}
			/>
		</Stack.Navigator>
	);
}
