import React, { useEffect, useState } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer } from '@react-navigation/native';
import Stage1FirstOpen from './stage1';
import Stage2FirstOpen from './stage2';
import Stage3FirstOpen from './stage3';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { View } from 'react-native';
import tw from '../../lib/tailwind';

export default function FirstOpenIndex({ navigation }) {
    const Tab = createMaterialTopTabNavigator();

    return (
        <View style={tw.style('flex-1 bg-white')}>
            <Tab.Navigator
                screenOptions={{ headerShown: false, gestureEnabled: true }}
                initialRouteName="stage1"
                tabBar={() => null}
            >
                <Tab.Screen name="stage1" component={Stage1FirstOpen} />
                <Tab.Screen name="stage2" component={Stage2FirstOpen} />
                <Tab.Screen name="stage3" component={Stage3FirstOpen} />
            </Tab.Navigator>
        </View>
    );
}
