/* eslint-disable require-jsdoc */
import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import tw from '../lib/tailwind';

export default function BackButton({ onPress, hidden, extraStyles = '' }) {
    return (
        <View
            style={[
                tw.style(
                    'w-1/4 pr-1.5 ' +
                        extraStyles +
                        ' ' +
                        (hidden ? 'opacity-0' : '')
                ),
                { zIndex: 1 },
            ]}
        >
            <TouchableOpacity
                style={[
                    tw.style(
                        'flex justify-center items-center bg-white py-4 border border-gray-200 rounded-xl'
                    ),
                    { zIndex: 1 },
                ]}
                onPress={onPress}
            >
                <View>
                    <Icon
                        name="arrow-back"
                        size={24}
                        color={tw.color('primary')}
                    />
                </View>
            </TouchableOpacity>
        </View>
    );
}
