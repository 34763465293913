import {Dimensions, Platform, PixelRatio} from 'react-native';

const {width: SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('window');

// based on iphone 5s's scale
const scale = SCREEN_WIDTH / 320;

export function normalizeTextSize(size) {
	const newSize = size * scale;
	return Math.round(PixelRatio.roundToNearestPixel(newSize));
}
