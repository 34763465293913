import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
	Alert,
	Dimensions,
	Keyboard,
	KeyboardAvoidingView,
	Platform,
	TouchableOpacity,
	TouchableWithoutFeedback,
} from 'react-native';
import {TextInput} from 'react-native';
import {View, Text} from 'react-native';
import {CheckBox, Icon} from 'react-native-elements';
import {SafeAreaView} from 'react-native-safe-area-context';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../lib/tailwind';
import {selectHeaderBarLayout} from '../../slices/headerBarSlice';
import {
	setArrivalScreenAfterRegistration,
	setEmail,
	setUserID,
} from '../../slices/registerUserSlice';
import {setToken, setUserInformation} from '../../slices/userSlice';
import BackButton from '../../uiKit/backButton';
import HeaderBar from '../../uiKit/headerBar';
import Input from '../../uiKit/input';
import NextButton from '../../uiKit/nextButton';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {isDevice} from 'expo-device';
import * as Notifications from 'expo-notifications';

export default function LoginScreen({navigation, route}) {
	const [email, setEmailState] = useState('');
	const [password, setPassword] = useState('');

	const [buttonLoading, setButtonLoading] = useState(false);

	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	const nextScreen = route.params.screen;

	const [persistLogin, setPersistLogin] = useState(false);

	const dispatch = useDispatch();

	async function registerForPushNotificationsAsync(token) {
		if (isDevice) {
			const {status: existingStatus} =
				await Notifications.getPermissionsAsync();
			let finalStatus = existingStatus;
			if (existingStatus !== 'granted') {
				const {status} = await Notifications.requestPermissionsAsync();
				finalStatus = status;
			}
			if (finalStatus !== 'granted') {
				Alert.alert(
					'Fehler',
					'Push-Benachrichtigungen wurden verweigert, bitte aktiviere diese in deinen Einstellungen.'
				);
				return;
			}
			const pushToken = (await Notifications.getExpoPushTokenAsync()).data;
			console.log(pushToken);

			try {
				// Send the token to your backend server from where you can retrieve it to send push notifications.
				let res = await axios.post(
					process.env.APIURL + 'device',
					{
						expoPushToken: pushToken,
					},
					{
						headers: {'Authorization': `Bearer ${token}`},
					}
				);
				console.log(res);
			} catch (error) {
				alert(
					'Error while registering device for push notifications',
					error.response
				);
				console.log(error.response);
			}

			// This listener is fired whenever a notification is received while the app is foregrounded
			Notifications.addNotificationReceivedListener((notification) => {
				console.log(notification);
			});
		} else {
			Alert.alert(
				'Fehler',
				'Dieses Gerät unterstützt keine Push-Benachrichtigungen'
			);
		}

		if (Platform.OS === 'android') {
			Notifications.setNotificationChannelAsync('default', {
				name: 'default',
				importance: Notifications.AndroidImportance.MAX,
				vibrationPattern: [0, 250, 250, 250],
				lightColor: tw.color('primary'),
			});
		}
	}

	async function signIn() {
		Keyboard.dismiss();
		if (email.trim() === '') {
			Alert.alert('Fehler', 'Bitte gib deine E-Mail-Adresse ein');

			return;
		}

		if (password.trim() === '') {
			Alert.alert('Fehler', 'Bitte gib dein Passwort ein');
			return;
		}
		setButtonLoading(true);
		try {
			let res = await axios.post(
				process.env.APIURL + 'token',
				{username: email.trim(), password: password.trim()},
				{
					'headers': {
						'content-type': 'application/json',
					},
				}
			);

			const data = res.data.data;

			dispatch(setToken(data.accessToken));
			dispatch(setUserInformation(data.user));
			/*LogRocket.identify(data.user.id, {
							user: data.user,
						});*/
			setButtonLoading(false);

			if (persistLogin) {
				if (Platform.OS === 'web') {
					localStorage.setItem(
						'@ubring_login',
						JSON.stringify({
							accessToken: data.accessToken,
							user: data.user,
						})
					);
				}

				try {
					await registerForPushNotificationsAsync(data.accessToken);

					await AsyncStorage.setItem(
						'@ubring_login',
						JSON.stringify({
							accessToken: data.accessToken,
							user: data.user,
						})
					);
				} catch (error) {
					console.log(error);
				}
			}

			navigation.replace('authenticatedContainer', {
				screen: nextScreen,
			});
		} catch (error) {
			setButtonLoading(false);

			if (error.response.data.code === 'user_not_activated') {
				dispatch(setEmail(error.response.data.user.email));
				dispatch(setUserID(error.response.data.user.id));
				navigation.push('register', {screen: 'validateCode'});
				return;
			}
			if (error.response) {
				console.log(error.response.data);
				Alert.alert(
					'Fehler',
					error.response.data.message ||
						'Es ist ein Fehler aufgetreten, bitte versuche es später erneut'
				);
			}
		}
	}

	return (
		<TouchableWithoutFeedback
			onPress={() => {
				if (Platform.OS != 'web') {
					Keyboard.dismiss();
				}
			}}
		>
			<View style={tw.style('flex-1 bg-background')}>
				<HeaderBar siteTitle="U-Login" />
				<View style={tw.style('flex-1 justify-between px-6')}>
					<View style={tw.style('')}>
						<Text style={tw.style('font-bold text-xl my-7 text-center')}>
							Melde dich an und los geht's!
						</Text>
						<Input
							onChangeText={(text) => setEmailState(text)}
							value={email}
							accessibilityLabel="E-Mail eingeben"
							accessibilityRole="text"
							placeholder="E-Mail"
							autoCapitalize="none"
							keyboardType="email-address"
						/>
						<Input
							onChangeText={(text) => setPassword(text)}
							value={password}
							secureTextEntry={true}
							accessibilityLabel="Passwort eingeben"
							accessibilityRole="text"
							clearButtonMode="while-editing"
							placeholder="Passwort"
						/>
						<CheckBox
							checked={persistLogin}
							onPress={() => setPersistLogin(!persistLogin)}
							containerStyle={tw.style(
								'bg-background shadow-none m-0 p-0 border-0 bg-opacity-0'
							)}
							titleProps={{
								style: tw.style('text-sm ml-1'),
							}}
							checkedIcon={
								<Icon name="check-box" color={tw.color('primary')} />
							}
							uncheckedIcon={
								<Icon
									name="check-box-outline-blank"
									color={tw.color('primary')}
								/>
							}
							title={'Angemeldet bleiben'}
						/>
						<TouchableOpacity
							style={tw.style('mt-3')}
							onPress={() => navigation.push('requestPasswordReset')}
						>
							<Text style={tw.style('text-primary text-lg')}>
								Passwort vergessen?
							</Text>
						</TouchableOpacity>
					</View>

					<KeyboardAvoidingView
						behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
						keyboardVerticalOffset={HeaderBarLayout.height + 14}
					>
						<TouchableOpacity
							style={tw.style('mb-3')}
							onPress={() => {
								navigation.push('register');
								dispatch(setArrivalScreenAfterRegistration(nextScreen));
							}}
						>
							<Text style={tw.style('text-primary text-lg')}>
								Noch kein Konto? Registrier dich!
							</Text>
						</TouchableOpacity>
						<View style={tw.style('flex flex-row justify-center w-full mb-4')}>
							<BackButton onPress={() => navigation.goBack()} />
							<NextButton
								onPress={() => signIn()}
								label="Anmelden"
								showIcon={buttonLoading}
								loading={buttonLoading}
								disabled={buttonLoading}
							/>
						</View>
					</KeyboardAvoidingView>
				</View>
			</View>
		</TouchableWithoutFeedback>
	);
}
