import {
	View,
	Text,
	ScrollView,
	TextInput,
	KeyboardAvoidingView,
	Platform,
} from 'react-native';
import React from 'react';
import tw from '../lib/tailwind';
import {AirbnbRating, Rating} from 'react-native-elements';
import BackButton from '../uiKit/backButton';
import NextButton from '../uiKit/nextButton';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {selectToken} from '../slices/userSlice';
import {Alert} from 'react-native';
import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';

export default function CreateRating({navigation, route}) {
	const {ratingRecipientID, shipmentID} = route.params;

	const [rating, setRating] = React.useState(5);
	const [comment, setComment] = React.useState('');

	const token = useSelector(selectToken);

	const [buttonDisabled, setButtonDisabled] = React.useState(false);
	const [buttonLoading, setButtonLoading] = React.useState(false);

	async function submitRating() {
		setButtonDisabled(true);
		setButtonLoading(true);
		try {
			let res = await axios.post(
				process.env.APIURL + 'user/rating',
				{
					recipientID: ratingRecipientID,
					rating: rating,
					comment: comment,
					shipmentID: shipmentID,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			);

			if (res.status == 200) {
				Alert.alert(
					'Bewertung erfolgreich abgegeben',
					'Wir haben deine Bewertung erfolgreich abgespeichert. Vielen Dank für deine Hilfe!'
				);
				navigation.goBack();
			}

			setButtonDisabled(false);
			setButtonLoading(false);
		} catch (error) {
			setButtonDisabled(false);
			setButtonLoading(false);

			if (error.response.data.message == 'user_does_not_exist') {
				Alert.alert(
					'Fehler',
					'Es gab einen Fehler beim Bewerten dieser Person. Bitte versuche es später erneut.'
				);
				navigation.goBack();
				return;
			}

			Alert.alert(
				'Bereits bewertet',
				'Du hast diese Person bereits für diesen Transport bewertet.'
			);
			navigation.goBack();
		}
	}

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
			style={tw.style('flex-1')}
		>
			<KeyboardAwareScrollView
				style={tw.style('flex-1 p-8')}
				enableAutomaticScroll
				enableOnAndroid
			>
				<View>
					<Text style={tw.style('text-2xl font-bold')}>
						Bewertung schreiben
					</Text>
					<View style={tw.style('mt-10')}>
						<Text style={tw.style('text-xl')}>Wie war deine Erfahrung?</Text>
						<View style={tw.style('flex flex-row mt-2')}>
							<AirbnbRating
								count={5}
								defaultRating={5}
								size={30}
								selectedColor={tw.color('primary')}
								showRating={false}
								isDisabled={false}
								onFinishRating={(rating) => setRating(rating)}
							/>
						</View>
					</View>
					<View style={tw.style('mt-10')}>
						<Text style={tw.style('text-xl')}>
							Möchtest du etwas dazu schreiben? (optional)
						</Text>
						<View style={tw.style('flex flex-row mt-2')}>
							<TextInput
								style={[
									tw.style(
										'border border-gray-300 bg-white rounded p-2 h-40 w-full'
									),
									{
										textAlignVertical: 'top',
									},
								]}
								placeholder="Schreibe hier..."
								multiline={true}
								numberOfLines={4}
								onChangeText={(text) => setComment(text)}
								value={comment}
								scrollEnabled={false}
								placeholderTextColor={tw.color('black')}
							/>
						</View>
					</View>
				</View>
				<View style={tw.style('flex flex-row justify-between mt-6')}>
					<BackButton hidden />
					<NextButton
						onPress={() => submitRating()}
						label="Speichern"
						disabled={buttonDisabled}
						loading={buttonLoading}
						showIcon={buttonLoading}
					/>
				</View>
			</KeyboardAwareScrollView>
		</KeyboardAvoidingView>
	);
}
