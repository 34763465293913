import {useNavigation} from '@react-navigation/native';
import axios from 'axios';
import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectToken} from '../../../slices/userSlice';
import BackButton from '../../../uiKit/backButton';

function Statistics(props) {
	const [statistics, setStatistics] = React.useState(null);

	const token = useSelector(selectToken);
	const navigation = useNavigation();

	useEffect(() => {
		getStats();
	}, []);

	async function getStats() {
		try {
			let res = await axios.get(process.env.APIURL + 'user/statistics', {
				headers: {'Authorization': `Bearer ${token}`},
			});

			if (res.data) {
				setStatistics(res.data);
				console.log(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	}

	if (statistics === null) {
		return (
			<View style={tw`flex-1 justify-center items-center`}>
				<Text>Loading...</Text>
			</View>
		);
	}

	return (
		<View style={tw.style('flex-1 p-6')}>
			<View style={tw.style('flex flex-row justify-between mb-6')}>
				<BackButton onPress={() => navigation.goBack()} />
				<Text style={tw.style('text-xl font-bold text-center w-1/2')}>
					Deine Statistiken
				</Text>
				<View style={tw.style('w-1/4')} />
			</View>
			{statistics.map(({title, value}) => {
				return (
					<View
						style={tw.style(
							'border-b border-gray-300 py-4 flex flex-row justify-between'
						)}
						key={title}
					>
						<Text style={tw.style('font-medium')}>{title}</Text>
						<Text>
							{title == 'Einnahmen'
								? parseInt(value / 100)
										.toFixed(2)
										.replace('.', ',') + ' €'
								: value}
						</Text>
					</View>
				);
			})}
		</View>
	);
}

export default Statistics;
