import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {
	View,
	Text,
	KeyboardAvoidingView,
	Platform,
	Dimensions,
	TextInput,
	Keyboard,
	TouchableOpacity,
	Alert,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectHeaderBarLayout} from '../../../slices/headerBarSlice';
import {selectRegisterUserInfo} from '../../../slices/registerUserSlice';
import {setToken, setUserInformation} from '../../../slices/userSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';

export default function ValidateCode({navigation}) {
	const [verifyCodeInput, setVerifyCode] = useState('');

	const [buttonDisabled, setButtonDisabled] = useState(true);

	const [isLoading, setIsLoading] = useState(false);

	const newUserInfo = useSelector(selectRegisterUserInfo);

	console.log(newUserInfo);

	const dispatch = useDispatch();

	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	useEffect(() => {
		if (verifyCodeInput.length === 6) {
			setButtonDisabled(false);
		} else {
			setButtonDisabled(true);
		}
	}, [verifyCodeInput]);

	function verifyCode() {
		setIsLoading(true);
		axios
			.post(process.env.APIURL + 'verify', {
				'code': verifyCodeInput,
				'id': newUserInfo.userID,
			})
			.then((res) => {
				if (!res.data.error && res.status === 200) {
					const data = res.data.data;
					dispatch(setToken(data.accessToken));
					dispatch(setUserInformation(data.user));
					setIsLoading(false);
					navigation.push('thankYou');
				} else {
					setIsLoading(false);
					Alert.alert(
						'Fehler',
						'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				if (error.response) {
					console.log(error.response.data);

					Alert.alert(
						'Fehler',
						error.response.data.message ||
							'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
					);
				}
			});
	}

	async function resendCode() {
		try {
			let res = await axios.post(process.env.APIURL + 'resendCode', {
				'id': newUserInfo.userID.toString(),
				'email': newUserInfo.email,
			});

			if (res.data.status) {
				Alert.alert(
					'Code wurde erneut versendet',
					'Bitte überprüfe deine E-Mail.'
				);
			}
		} catch (error) {
			console.log(error.response.data);
			Alert.alert(
				'Fehler',
				error.response.data.message ||
					'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.'
			);
		}
	}

	return (
		<View
			style={tw.style('w-full h-full flex justify-between items-center px-6')}
			removeClippedSubviews={false}
		>
			<View style={tw.style('w-full')} removeClippedSubviews={false}>
				<Text
					style={tw.style('text-xl font-bold text-gray-700 text-center my-7')}
				>
					Bitte gib den Dir per E-Mail zugesandten Bestätigungscode ein.
				</Text>

				<View
					style={tw.style('flex flex-col items-center justify-center')}
					removeClippedSubviews={false}
				>
					<Input
						onChangeText={(text) => setVerifyCode(text)}
						value={verifyCodeInput}
						placeholder="Code"
						disabled={false}
					/>
					<Text style={tw.style('text-xs text-gray-400 font-light mt--2 mb-4')}>
						(Überprüfe auch dein Spam-Ordner)
					</Text>
					<TouchableOpacity onPress={resendCode} style={tw.style('mt-4')}>
						<Text style={tw.style('text-xs text-accent font-medium underline')}>
							Neuen Code zusenden
						</Text>
					</TouchableOpacity>
				</View>
			</View>

			<View>
				<View style={tw.style('flex flex-row justify-center w-full mb-6')}>
					<BackButton
						onPress={() =>
							navigation.navigate('login', {
								params: {
									screen: newUserInfo.arrivalScreenAfterRegistration,
								},
								merge: true,
							})
						}
					/>
					<NextButton
						onPress={() => verifyCode()}
						label="Verifizieren"
						disabled={buttonDisabled}
						loading={isLoading}
						showIcon={isLoading}
					/>
				</View>
			</View>
		</View>
	);
}
