import {View, Text, Image} from 'react-native';
import React, {useState} from 'react';
import tw from '../../../lib/tailwind';
import BackButton from '../../../uiKit/backButton';
import NextButton from '../../../uiKit/nextButton';

import Übergeben from '../../../assets/images/illustrations/u-bring-delivery-absender_2.png';

export default function CompletedPackage({navigation, route}) {
	const params = route.params;

	function handOverPackage() {
		navigation.replace('authenticatedContainer', {
			screen: 'uMission',
		});
	}
	return (
		<View style={tw.style('flex-1 flex justify-between items-center p-6')}>
			<Text style={tw.style('text-xl font-bold text-center text-gray-800')}>
				Du hast Deine Sendung erfolgreich eingestellt. Wir melden uns bei Dir,
				wenn ein Bringer bereitsteht.
			</Text>
			<Image
				source={Übergeben}
				style={tw.style('w-5/5 h-75')}
				resizeMode="contain"
			/>

			<View style={tw.style('flex flex-row justify-between')}>
				<BackButton onPress={() => navigation.goBack()} />
				<NextButton
					onPress={() => handOverPackage()}
					label="Bestätigen"
					showIcon
				/>
			</View>
		</View>
	);
}
