import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';
import {
	View,
	Text,
	KeyboardAvoidingView,
	Platform,
	Dimensions,
	TextInput,
	Keyboard,
	TouchableOpacity,
	Image,
} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectHeaderBarLayout} from '../../../slices/headerBarSlice';
import {selectRegisterUserInfo} from '../../../slices/registerUserSlice';
import {setToken, setUserInformation} from '../../../slices/userSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';

import Illustration1 from '../../../assets/images/illustrations/ubring-register.png';

export default function ThankYouRegisterBring({navigation}) {
	return (
		<View
			style={tw.style('w-full h-full flex justify-between items-center px-6')}
		>
			<View style={tw.style('w-full')}>
				<Text
					style={tw.style('text-xl font-bold text-gray-700 text-center my-7')}
				>
					Du hast Dich erfolgreich als Bringer registriert. Wohin fährst Du als
					nächstes? Schau ob Du Pakete mitnehmen kannst.
				</Text>
			</View>

			<Image
				style={tw.style('m-10 w-8/10 h-4/10 ')}
				height={'40%'}
				width={'80%'}
				resizeMode="contain"
				source={Illustration1}
			/>

			<View>
				<View style={tw.style('flex flex-row justify-center w-full mb-6')}>
					<BackButton onPress={() => {}} hidden />
					<NextButton
						onPress={() =>
							navigation.replace('authenticatedContainer', {
								screen: 'uBring',
								merge: true,
							})
						}
						label="Weiter"
						showIcon
					/>
				</View>
			</View>
		</View>
	);
}
