import {
	View,
	Text,
	Alert,
	ScrollView,
	TouchableWithoutFeedback,
	Linking,
	Image,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import tw from '../../../lib/tailwind';
import {Avatar, CheckBox, Icon} from 'react-native-elements';
import NextButton from '../../../uiKit/nextButton';
import BackButton from '../../../uiKit/backButton';
import {useSelector} from 'react-redux';
import {selectToken} from '../../../slices/userSlice';
import axios from 'axios';
import dateFormat from 'dateformat';

export default function PackageInfo({navigation, route}) {
	const params = route.params?.data;

	const packageData = params.packageData;
	const routeID = params.routeID;

	var formatedPrice = packageData.price / 100;

	formatedPrice = formatedPrice.toFixed(2).replace('.', ',') + ' €';

	const token = useSelector(selectToken);

	const [loading, setLoading] = useState(false);
	const [startTime, setStartTime] = useState();

	const [checkLegal, setCheckLegal] = useState(false);

	useEffect(() => {
		if (!params) {
			navigation.goBack();
			return;
		}
		console.info(packageData);

		let startTimeDate = new Date().setTime(packageData.date);

		setStartTime(dateFormat(startTimeDate, 'dd.mm.yyyy'));
	}, []);

	function proposeToPackage() {
		setLoading(true);
		axios
			.put(
				process.env.APIURL + 'shipment/request',
				{
					shipmentID: parseInt(packageData.id),
					routeID: parseInt(routeID),
				},
				{
					headers: {'Authorization': `Bearer ${token}`},
				}
			)
			.then((res) => {
				if (res.data) {
					navigation.push('requestConfirmation');
					setLoading(false);
				}
			})
			.catch((e) => {
				console.log(e.response);
				setLoading(false);
			});
	}

	return (
		<ScrollView contentContainerStyle={tw.style('')}>
			<TouchableWithoutFeedback>
				<View style={tw.style('flex-1 p-6 flex justify-between')}>
					<View style={tw.style('w-full flex flex-row mb-4')}>
						<Avatar
							rounded
							size={84}
							source={{
								uri: packageData?.sender.sender.avatarSrc
									? packageData.sender.sender.avatarSrc
									: 'https://api.multiavatar.com/' +
									  packageData.user_email +
									  '.png',
							}}
						/>
						<View style={tw.style('ml-4 flex flex-col')}>
							<Text style={tw.style('text-accent text-base')}>u-send</Text>
							<Text style={tw.style('text-black text-2xl')}>
								{packageData.sender.sender.first_name}{' '}
								{packageData.sender.sender.last_name}
							</Text>
						</View>
					</View>
					<View
						style={tw.style(
							'bg-white flex flex-row rounded-xl shadow-md w-full overflow-hidden mb-6'
						)}
					>
						<Image
							source={{uri: packageData.imageSrc}}
							style={tw.style('w-1/3 min-h-[150px] bg-gray-300')}
						/>
						<View style={tw.style('w-2/3 p-3')}>
							<View style={tw.style('flex flex-row justify-between')}>
								<Text style={tw.style('font-bold text-base')}>Preis:</Text>
								<Text style={tw.style('font-bold text-accent text-3xl')}>
									{formatedPrice}
								</Text>
							</View>
							<View
								style={tw.style(
									'flex flex-row justify-between py-3 border-b border-gray-200'
								)}
							>
								<Text style={tw.style('font-bold text-base')}>
									Paket:{' "' + packageData.name + '"'}
								</Text>
							</View>
							{packageData.length != 9999 && (
								<View
									style={tw.style(
										'flex flex-row justify-between py-3 flex-wrap'
									)}
								>
									<Text style={tw.style('text-base')}>Größe:</Text>
									<Text style={tw.style('font-bold text-accent')}>
										<Text style={tw.style('font-bold text-base')}>
											{'L: ' +
												packageData.length +
												'cm / B: ' +
												packageData.width +
												'cm / H: ' +
												packageData.height +
												'cm'}
										</Text>
									</Text>
								</View>
							)}
						</View>
					</View>
					<View style={tw.style('')}>
						<View>
							<View style={tw.style('pb-1 border-b border-gray-200')}>
								<Text style={tw.style('text-accent text-xl')}>Start</Text>
							</View>
							<View
								style={tw.style(
									'flex flex-row justify-between py-.5 border-b border-gray-200'
								)}
							>
								<Text style={tw.style('text-base font-light')}>Adresse</Text>
								<Text
									style={tw.style('text-base font-light max-w-2/3 text-right')}
								>
									{packageData.sender.originAddress?.longString}
								</Text>
							</View>
							<View
								style={tw.style(
									'flex flex-row justify-between py-.5 border-b border-gray-200'
								)}
							>
								<Text style={tw.style('text-base font-light')}>Abfahrt</Text>
								<Text style={tw.style('text-base font-light')}>
									{startTime}
								</Text>
							</View>
							<View style={tw.style('pb-1 pt-4 border-b border-gray-200')}>
								<Text style={tw.style('text-primary text-xl')}>Ziel</Text>
							</View>
							<View
								style={tw.style(
									'flex flex-row justify-between py-.5 border-b border-gray-200'
								)}
							>
								<Text style={tw.style('text-base font-light')}>Adresse</Text>
								<Text
									style={tw.style('text-base font-light max-w-2/3 text-right')}
								>
									{packageData.sender.destinationAddress?.longString}
								</Text>
							</View>
							{/*<View
						style={tw.style(
							'flex flex-row justify-between py-.5 border-b border-gray-200'
						)}
					>
						<Text style={tw.style('text-base font-light')}>Ankunft</Text>
						<Text style={tw.style('text-base font-light')}>
							Wird berechnet...
						</Text>
					</View>*/}
						</View>
					</View>
					<CheckBox
						checked={checkLegal}
						onPress={() => setCheckLegal(!checkLegal)}
						containerStyle={tw.style(
							'bg-background shadow-none border-0 bg-opacity-0'
						)}
						checkedIcon={
							<Icon name="radio-button-checked" color={tw.color('primary')} />
						}
						uncheckedIcon={
							<Icon name="radio-button-unchecked" color={tw.color('primary')} />
						}
						title={
							<View style={tw.style('flex flex-row flex-wrap')}>
								<Text>Ich stimme den </Text>
								<Text
									onPress={() => Linking.openURL('https://www.u-bring.de/agb')}
									style={tw.style('underline')}
								>
									AGB
								</Text>
								<Text> und der </Text>
								<Text
									onPress={() =>
										Linking.openURL('https://u-bring.de/widerrufsrecht/')
									}
									style={tw.style('underline')}
								>
									Widerrufsbelehrung
								</Text>
								<Text> zu.</Text>
							</View>
						}
					/>
					<View style={tw.style('flex flex-row justify-between')}>
						<BackButton onPress={() => navigation.goBack()} />
						<NextButton
							label="Auftrag anfragen"
							onPress={() => {
								/*Alert.alert(
							'Anfrage senden',
							'Willst du die Anfrage wirklich senden? Wird deine Anfrage angenommen, kannst du diese nicht mehr stornieren.',
							[
								{
									text: 'Abbrechen',
									onPress: () => console.log('Cancel Pressed'),
									style: 'cancel',
								},
								{
									text: 'Senden',
									onPress: () => {
										
									},
								},
							],
							{cancelable: true}
						);*/
								proposeToPackage();
							}}
							loading={loading}
							disabled={loading || !checkLegal}
							showIcon={loading}
						/>
					</View>
				</View>
			</TouchableWithoutFeedback>
		</ScrollView>
	);
}
