import React, {useEffect, useState} from 'react';
import {
	View,
	Text,
	KeyboardAvoidingView,
	Platform,
	Dimensions,
} from 'react-native';
import {CheckBox, Icon} from 'react-native-elements';
import {useDispatch, useSelector} from 'react-redux';
import tw from '../../../lib/tailwind';
import {selectHeaderBarLayout} from '../../../slices/headerBarSlice';
import {setPhoneNumber} from '../../../slices/registerUserSlice';
import BackButton from '../../../uiKit/backButton';
import Input from '../../../uiKit/input';
import NextButton from '../../../uiKit/nextButton';

export default function PhoneNumberForm({navigation}) {
	const dispatch = useDispatch();

	const [number, setNumber] = useState('');
	const [buttonDisabeld, setButtonDisabled] = useState(true);

	const [numberError, setNumberError] = useState(false);

	const HeaderBarLayout = useSelector(selectHeaderBarLayout);

	useEffect(() => {
		if (number !== '') {
			var phone = number
				.replace(' ', '')
				.replace('+49', '0')
				.replace('(', '')
				.replace(')', '')
				.replace('-', '')
				.replace('/', '');

			if (phone.match(/^[0][0-9]*$/) && phone.length > 6) {
				setButtonDisabled(false);
				setNumberError(false);
			} else {
				setButtonDisabled(true);
				setNumberError('Die Nummer ist nicht korrekt.');
			}
		}
	}, [number]);

	function saveEntry() {
		dispatch(
			setPhoneNumber(
				number
					.replace(' ', '')
					.replace('+49', '0')
					.replace('(', '')
					.replace(')', '')
					.replace('-', '')
					.replace('/', '')
			)
		);
		navigation.push('emailForm');
	}

	return (
		<View
			style={[
				tw.style('w-full h-full flex justify-between items-center px-6'),
				{zIndex: 1},
			]}
		>
			<View style={tw.style('w-full')}>
				<Text
					style={tw.style('text-xl font-bold text-gray-700 text-center my-7')}
				>
					Bitte gib deine Handynummer ein.
				</Text>
				<Input
					placeholder="Mobilnummer"
					width="full"
					onChangeText={(text) => setNumber(text)}
					value={number}
					type="phone"
					keyboardType="phone-pad"
					error={numberError}
				/>
				<Text style={tw.style('mt--2 font-light text-sm text-gray-900')}>
					Deine Mobilnummer fängt mit 0 oder +49 an.
				</Text>
			</View>

			<View>
				<View
					style={[
						tw.style('flex flex-row justify-center w-full mb-6'),
						{zIndex: 1},
					]}
				>
					<BackButton onPress={() => navigation.goBack()} />
					<NextButton
						onPress={() => saveEntry()}
						label="Weiter"
						showIcon
						disabled={buttonDisabeld}
					/>
				</View>
			</View>
		</View>
	);
}
